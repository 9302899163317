<template transition="scale-transition">
  <v-container >
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" class="pl-3">
        <DataTable
          ref="AvailabilityTable"
          :headers="headers"
          name="AvailabilityTable"
          :title="$t('components.layout.sidebar.availability')"
          :url="url"
          :searchP="searchField"
          :height="$vuetify.breakpoint.xl ? 800 : 650"
          :urlCount="urlCount"
          id="availability"
        >

          <template
              v-for="header in headers"
              v-slot:[`header.${header.value}`]
              #activator="{ on, attrs }"
            >
            <div class="header-item">
              <div class="header-text">
                <span class="header-title">{{ header.text }}</span>
              </div>
            </div>
          </template>

          <template #[`item.state`]="{ item }">
            <v-icon
              :class="
                item.state == 'Open'
                  ? 'blue--text fa-solid fa-circle mr-2'
                  : item.state == 'Cancelled'
                  ? 'red--text fa-solid fa-circle mr-2'
                  : 'green--text fa-solid fa-circle mr-2'
              "
            />
            {{
              item.state == "Open"
                ? $t("views.reports.status_offers.table.open")
                : item.state == "Cancelled"
                ? $t("views.reports.status_offers.table.cancelled")
                : $t("views.reports.status_offers.table.confirmed")
            }}
          </template>
          <template #[`item.createdOn`]="{ item }">
            {{ moment.utc(item.createdOn, "DD/MM/YYYY H:mm").local().format("DD/MM/YYYY H:mm") }}
          </template>
          <template #[`item.updateOn`]="{ item }">
            {{
              item.updateOn == null || item.updateOn == ""
                ? $t("views.reports.status_offers.table.no_confirmed")
                : moment.utc(item.updateOn, "DD/MM/YYYY H:mm").local().format("DD/MM/YYYY H:mm")
            }}
          </template>
        </DataTable>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import validate from "@/plugins/validate";

export default {
  data() {
    return {
      ...validate,
      modalDownload: false,
      idMonitoringOrder: 0,
      index: 0,
      valida: false,
      dialogDetails: false,
      dateDownload: [],
      menu: false,
    };
  },
  name: "TableComponent",
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    dateRangeText() {
      return this.dateDownload.join(" A ");
    },
    idCompany() {
      let userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    url() {
      return `/Offers/ReportsToDrivers`;
    },
    urlCount() {
      return `/Offers/TotalReportsToDrivers`;
    },
    searchField() {
      return this.search;
    },
    headers() {
      return [
        {
          text: this.$t("views.reports.availability.table.upload_appointment_date"),
          class: "text-center",
          align: "center",
          value: "origin_schedule_date",
        },
        {
          text: this.$t("views.reports.availability.table.no_trip"),
          class: "text-center",
          align: "center",
          value: "trip_number",
        },
        {
          text: this.$t("views.reports.availability.table.type_operation"),
          class: "text-center",
          align: "center",
          value: "type_operation",
        },
        {
          text: this.$t("views.reports.availability.table.driver_name"),
          class: "text-center",
          align: "center",
          value: "full_name",
        },
        {
          text: this.$t("views.reports.availability.table.driver_phone"),
          class: "text-center",
          align: "center",
          value: "driver_phone",
        },
        {
          text: this.$t("views.reports.availability.table.license_plate"),
          class: "text-center",
          align: "center",
          value: "license_plate",
        },
        {
          text: this.$t("views.reports.availability.table.typology_vehicle"),
          class: "text-center",
          align: "center",
          value: "vehicle_typology",
        },
        {
          text: this.$t("views.reports.availability.table.state_confirm"),
          class: "text-center",
          align: "center",
          value: "state_offer",
        },
        {
          text: this.$t("views.reports.availability.table.origin_city"),
          class: "text-center",
          align: "center",
          value: "origin_city_name",
        },
        {
          text: this.$t("views.reports.availability.table.destiny_city"),
          class: "text-center",
          align: "center",
          value: "destiny_city_name",
        },
        {
          text: this.$t("views.reports.availability.table.availability_zone"),
          class: "text-center",
          align: "center",
          value: "zone_disponibility",
        },
        {
          text: this.$t("views.reports.availability.table.publish_date"),
          class: "text-center",
          align: "center",
          value: "publish_date",
        },
      ];
    },
  },
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.status_of_offers"),
    };
  },
  watch: {
    dateDownload(newVal) {
      if (newVal[1] !== undefined) {
        let date1 = this.moment(newVal[0]),
          date2 = this.moment(newVal[1]);
        let diff = Math.abs(date2.diff(date1, "days"));
        if (diff > 30) {
          this.showSnack({
            text: this.$t("views.reports.availability.table.download_excel_range_title"),
            title: this.$t("views.reports.availability.table.download_excel_range_message"),
            name: "warning",
          });
        }
      }
    },
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) {
          window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
        }
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),
    ...mapActions("snackbar", ["showSnack"]),

    onRealoadTable() {
      this.$refs.AvailabilityTable.loadData();
    },

  },
};
</script>
<style scoped>
#status_offers {
  height: 100%;
}

.header-title {
    font-weight: bolder;
    color: #0B0045;
  }

  .v-application .px-4 {
    padding-right: 10px !important;
    padding-left: 20px !important;
}
</style>
